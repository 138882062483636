export default {
  async calculateShippingRate(request: ShippingRateRequest): Promise<ShippingRateResponse> {
    return await apiPost('pancake/shipping-rate', { body: request })
  },
  async createShippingAddress(request: CreateShippingAddressRequest): Promise<ShippingAddress> {
    return await apiPost(`shipping-address`, { body: request })
  },
  async updateShippingAddress(shippingAddressId: number, request: UpdateShippingAddressRequest): Promise<ShippingAddress> {
    return await apiPut(`shipping-address/${shippingAddressId}`, { body: request })
  },

  // Self
  async findSelfShippingAddresses(): Promise<ShippingAddress[]> {
    return await apiGet(`customer/self/shipping-address`)
  },
  async createSelfShippingAddress(request: CreateSelfShippingAddressRequest): Promise<ShippingAddress> {
    return await apiPost(`customer/self/shipping-address`, { body: request })
  },
}

export interface ShippingAddressInfo {
  street: string,
  number: string,
  complement: string,
  district: string,
  city: string,
  state: string,
  postalCode: string
}

export interface ShippingAddress {
  tenant: string,
  id: number,
  street: string,
  number: string,
  complement: string,
  district: string,
  city: string,
  state: string,
  postalCode: string,
  createdAt: string,
  updatedAt: string
}

export interface ShippingRateRequest {
  customerEmail: string,
  state: string,
  postalCode: string
}

export interface ShippingRateResponse {
  shippingService: string,
  days: number,
  price: number
}

export interface CreateShippingAddressRequest {
  customerId: number,
  street?: string,
  number?: string,
  complement?: string,
  district?: string,
  city?: string,
  state?: string,
  postalCode?: string
}

export interface CreateSelfShippingAddressRequest {
  street?: string,
  number?: string,
  complement?: string,
  district?: string,
  city?: string,
  state?: string,
  postalCode?: string
}

export interface UpdateShippingAddressRequest {
  street?: string,
  number?: string,
  complement?: string,
  district?: string,
  city?: string,
  state?: string,
  postalCode?: string
}